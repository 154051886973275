import ReactGA from 'react-ga'
import { LOCATION_CHANGE } from 'react-router-redux'
import * as actionTypes from '../actionTypes'

const options = {}

const trackPage = (page) => {
    ReactGA.set({
        page,
        ...options
    })
    ReactGA.pageview(page)
}

let currentPage = ""

export const googleAnalytics = store => next => action => {

    if (action.type === LOCATION_CHANGE) {
        const nextPage = `${action.payload.pathname}${action.payload.search}`

        if (currentPage !== nextPage) {
            currentPage = nextPage
            trackPage(nextPage)
        }
    }
    return next(action)
}