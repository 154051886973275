import * as actionTypes from '../actionTypes'

export const cargas = (state = {
    pagina: { estado: true, velocidad: 1000 },
    contenido: { estado: true, velocidad: 1000 }

}, action) => {
    switch (action.type) {
        case actionTypes.SET_CARGA_PAGINA:
            return { ...state, pagina: action.payload }

        case actionTypes.SET_CARGA_CONTENIDO:
            return { ...state, contenido: action.payload }

        default:
            return state
    }
}