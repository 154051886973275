export default {
    "APP_NAME": "EncuestasMM",
    "APPLICATION_ID": "4c2356ad2f8211f3528f48b21c0d3dcc",
    "TRIPCOM_KEY": "-----BEGIN PUBLIC KEY----- MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDisecqGyPYfsMdSXfZEW1oTpas 3On9Aq7881Ditc19vYsnfaq2DO05lQC8FnCa4hiUYT4aIn6SypVIMYuGTwGUxoBm C/cLYEy3etkvw+x8wCwr9GRtGQ+NSCml8ynkgW4Sm9SxPfrH7BiXGeVT2b5E4F8M /3bx/5prQX5B2H+9XwIDAQAB -----END PUBLIC KEY-----",
    "ROUTES": {
        "RPA": {
            "AUTH": {
                "INICIO_SESION": "/",
            },
            "SERVICIOS": {
                "PRINCIPAL": "/encuesta/dashboard/encuestas"
            }
        }
    },
    "RECAPTCHA3": {
        "CLIENT_KEY": "6LcdftQZAAAAACAlgiebwSE4W4FF1XBE66cF2ac0",
        "PRIVACY": "https://policies.google.com/privacy",
        "TERMS": "https://policies.google.com/terms"
    },
    "GOOGLE_ANALYTICS": {
        "TAG": "UA-195792413-1"
    },
    "CREDENTIALS": {
        "API_ENCRYPT": {
            "ACCESSKEYID": "AKIAZKBUTVM7ZA5Z7KNZ",
            "SECRETACCESSKEY": "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"
        }
    },
    "KEYS": {
        "API_ENCRYPT": [
            "70fbcd92-4c1c-4870-aca2-c4244aeae4d9",
            "39b99cd9-3792-4ce1-880c-8703243d1db1"
        ]
    },
    "API": process.env.REACT_APP_ENV === "production" ? {
        "API_ENCUESTA": "https://4munxg65rl.execute-api.us-east-1.amazonaws.com/dev"
        //"API_ENCUESTA":"http://localhost:3000/dev"
    } : {
        "API_ENCUESTA": "https://4munxg65rl.execute-api.us-east-1.amazonaws.com/dev"
        //"API_ENCUESTA":"http://localhost:3000/dev"
    },
    "CONTROLLERS": {

        "ENCUESTAS": "encuesta-mm",//
        "REPORTE_ENCUESTA": "encuesta-mm-dashboard"//
    },
    RECAPTCHA: {
        REACT_APP_SECRET_KEY: "6LcxN2YjAAAAAE9TwMCzyY_zSLqDyWCDUo73nEaG",
        REACT_APP_SITE_KEY: "6LcxN2YjAAAAAHkoIJJQnByyAOjFe4vpTVnx3u3l",
    }
}