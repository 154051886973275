import * as actionTypes from '../actionTypes'

export const notificaciones = (state = {
    popularVirtual: {
        estado: false
    }
}, action) => {
    switch (action.type) {
        case actionTypes.SET_ESTADO_NOTIFICACION_PP:
            return {
                ...state,
                popularVirtual: {
                    ...state.popularVirtual,
                    estado: action.payload
                }
            }

        default:
            return state
    }
}