export const web = {
    detalleColumna: tema => ({
        paper: {
            padding: tema.spacing(1),
            textAlign: "center",
            color: tema.palette.text.secondary
        }
    }),
    tituloSeccion: tema => ({
        titulo: {
            fontWeight: "bold",
            color: tema.palette.text.secondary

        },
        tituloConMargen: {
            marginTop: tema.spacing(1)
        }
    })
}