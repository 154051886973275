//Moment
import 'moment/locale/es'
//Perfect ScrollBar
import 'react-perfect-scrollbar/dist/css/styles.css'
//Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import 'font-awesome/css/font-awesome.min.css'
//Base
import "@sweetalert2/themes/material-ui/material-ui.css"
import './base.scss'
import './fonts.scss'
import 'animate.css'
//
library.add(fas, fab, far)