import { LOCATION_CHANGE } from 'react-router-redux'
import * as actionTypes from '../actionTypes'

export const navegacion = (state = {
    ubicacionActual: null,
    ubicacionAnterior: null
}, action) => {
    switch (action.type) {
        case actionTypes.ASIGNAR_RUTA_INICIAL:

            if (state.ubicacionActual === null && state.ubicacionAnterior === null) {
                return {
                    ...state,
                    ubicacionActual: action.payload.pathname
                }

            } else {
                return state
            }

        case LOCATION_CHANGE:

            if (action.payload.pathname !== state.ubicacionActual) {
                return {
                    ubicacionActual: action.payload.pathname,
                    ubicacionAnterior: state.ubicacionActual
                }

            } else {
                return state
            }

        default:
            return state
    }
}