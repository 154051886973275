import { createTheme } from '@mui/material/styles'

const colores = {
    primary: {
        main: "#3663A6",// fuerte
        light: "#0d1a66",
        dark: "#02061e"
    },
    secondary: {
        main: "#5F5F5F",
        light: "#666666",
        dark: "#424242"
    },
    popularVirtual: {
        main: "#752F8A",
        light: "#9058a1",
        dark: "#512060",
        contrastText: "#FFFFFF"
    }
}

export const tema = createTheme({
    personalizado: {
        public: {},
        rpa: {
            detalles: require("./personalizado/rpa/detalles")
        },
        general: {
            general: require("./personalizado/general/general")
        }
    },
    palette: {
        primary: {
            main: colores.primary.main,
            light: colores.primary.light,
            dark: colores.primary.dark,
        },
        secondary: {
            main: colores.secondary.main,
            light: colores.secondary.light,
            dark: colores.secondary.dark
        },
        popularVirtual: {
            main: colores.popularVirtual.main,
            light: colores.popularVirtual.light,
            dark: colores.popularVirtual.dark,
            contrastText: colores.popularVirtual.contrastText
        },
        contrastThreshold: 4.5
    },
    typography: {
        h1: {
            fontFamily: "Rubik-Medium"
        },
        h2: {
            fontFamily: "Rubik-Medium"
        },
        h3: {
            fontFamily: "Rubik-Medium"
        },
        h4: {
            fontFamily: "Rubik-Medium"
        },
        h5: {
            fontFamily: "Rubik-Medium"
        },
        h6: {
            fontFamily: "Rubik-Medium"
        },
        fontFamily: "Rubik-Regular"
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: "Rubik-Regular"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                },
                label: {
                    fontFamily: "Rubik-Regular"
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: 0
                }
            }
        }
    }
})