import './app/loader'
//
import React, { Suspense, lazy } from 'react'
import services from './efika/services'
import {createRoot} from 'react-dom/client'
import registerServiceWorker from './registerServiceWorker'
import ReduxToastr from 'react-redux-toastr'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoadingOverlay from 'react-loading-overlay-ts'
import { BreakpointProvider } from 'react-point-break'
//
import { Provider } from 'react-redux'
import { Router, BrowserRouter } from 'react-router-dom'
import { syncHistoryWithStore } from 'react-router-redux'
import { configureStore } from './redux/configureStore'
import efikaHistory from './efika/history'
//
import ReactGA from 'react-ga'
//
import { ThemeProvider } from '@mui/material/styles'
import { tema } from './app/tema/base'

const MainRouter = lazy(() => import('./app/router'))
const store = configureStore()
const history = syncHistoryWithStore(efikaHistory, store)
//
ReactGA.initialize(services.GOOGLE_ANALYTICS.TAG)
const element = document.getElementById("root");
if(!element)throw new Error('Root error');
const root = createRoot(element)
root.render(
   <Provider store={store}>
      <BreakpointProvider
         xs={`(min-width: ${tema.breakpoints.values.xs}px) and (max-width: ${tema.breakpoints.values.sm}px)`}
         sm={`(min-width: ${tema.breakpoints.values.sm}px) and (max-width: ${tema.breakpoints.values.md}px)`}
         md={`(min-width: ${tema.breakpoints.values.md}px) and (max-width: ${tema.breakpoints.values.lg}px)`}
         lg={`(min-width: ${tema.breakpoints.values.lg}px) and (max-width: ${tema.breakpoints.values.xl}px)`}
         xl={`(min-width: ${tema.breakpoints.values.xl}px)`}
      >
         <ThemeProvider theme={tema}>
            <BrowserRouter history={history}>
               <Suspense fallback={
                  <LoadingOverlay
                     active={true}
                     styles={{
                        overlay: base => ({
                           ...base,
                           position: "fixed",
                           background: "white"
                        })
                     }}
                     spinner={null}
                  />
               }>
                  <ToastContainer limit={5} />
                  <MainRouter />
                  <ReduxToastr
                     timeOut={4000}
                     newestOnTop={false}
                     preventDuplicates
                     position="top-left"
                     transitionIn="fadeIn"
                     transitionOut="fadeOut"
                     progressBar
                     closeOnToastrClick
                  />
               </Suspense>
            </BrowserRouter>
         </ThemeProvider>
      </BreakpointProvider>
   </Provider>
   
)
//registerServiceWorker()
