import { createStore, combineReducers, applyMiddleware } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as toastrReducer } from "react-redux-toastr"
import { thunk } from 'redux-thunk'
import { all as publicMiddlewares } from './public/middlewares/all'
import { all as publicReducers } from './public/reducers/all'
import { all as popularVirtualMiddlewares } from './popularVirtual/middlewares/all'
import { all as popularVirtualReducers } from './popularVirtual/reducers/all'
import { all as rpaReducers } from './rpa/reducers/all'
import { all as generalReducers } from './general/reducers/all'

export const configureStore = () => {
    const store = createStore(
        combineReducers({
            //General
            toastr: toastrReducer,
            routing: routerReducer,
            ...publicReducers,
            ...popularVirtualReducers,
            ...rpaReducers,
            ...generalReducers
        }),
        applyMiddleware(
            //General
            thunk,
            //logger,
            ...publicMiddlewares,
            ...popularVirtualMiddlewares
        )
    )
    return store
}