export const web = {
    progresoCircularCarga: tema => ({
        contenedor: {
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            top: 0,
            left : 0,
            width: "100%",
            height: "100%",
            alignItems: "center"
        }
    })
}

export const movil = {}